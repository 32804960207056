<template>
	<div>
		<div class="search">
			<div class="a-inline-block">
				<label for="">员工姓名</label><a-input v-model="name" size="large" placeholder="请输入员工姓名" />
			</div>
			<div class="a-inline-block">

				<label for="">所属部门</label>

				<a-select size="large" v-model="department_id" placeholder="请选择所属部门">
					<a-select-option v-for="(item, index) in treeData" :key="index" :value="item.key">
						{{ item.title }}
					</a-select-option>
				</a-select>
			</div>
			<div class="a-inline-block">
				<label for="">是否发放搜索</label>
				<a-select size="large" v-model="status">
					<a-select-option :value="null">全部</a-select-option>
					<a-select-option :value="1">是</a-select-option>
					<a-select-option :value="0">否</a-select-option>
				</a-select>
			</div>
			<div class="a-inline-block">
				<label for="">计算方式搜索</label>
				<a-select size="large" v-model="count_mode">
					<a-select-option :value="null">全部</a-select-option>
					<a-select-option :value="1">按单计算</a-select-option>
					<a-select-option :value="2">按收款金额计算</a-select-option>
				</a-select>
			</div>
			<div class="a-inline-block2">
				<label class="info" for="">时间</label>
				<a-date-picker v-model="date" size="large" format="YYYY-MM-DD" />
			</div>
			<div class="a-inline-block">
				<a-button size="large" type="primary" @click="onSearch">搜索</a-button>
				<a-button size="large" @click="reset">重置</a-button>
			</div>

			<!-- <div style="text-align:right;">
					<a-button style="width:auto;" size="large" type="primary" @click="delAll">批量删除</a-button>
				</div> -->
		</div>

		<div class="screen tMenu">
				<div class="a-inline-block" @click="excelout">
					<img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
				</div>
			</div>
		<a-table  :rowKey='record => record.id' :columns="columns" :data-source="data"
			class="table" id="myTable" :pagination="pagination" :scroll="{ x: true }">
			<span slot="num" slot-scope="text, record, index">
				{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
			</span>
			<span slot="status" slot-scope="text, record">
				<a-switch v-model="record.status" @change="changeStatus(record.status,record.id)" :checkedValue="1" :unCheckedValue="0" checked-children="已发放" un-checked-children="未发放" />
			</span>
			<template slot="Action" slot-scope="text,record">
				<!-- <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a>
				<a class="action" href="javascript:;" @click="editOrder(record.id)"
					v-if="record.status != '已完成' && record.status != '关闭'">编辑</a> -->

				<a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
					<a class="action del" href="javascript:;">删除</a>
				</a-popconfirm>
			</template>
		</a-table>
	</div>
</template>
<script>
// import $ from "jquery";
import { requestXml, getDateTime,ajaxUrl } from '../../../assets/js/request';
// var moment = require('moment');
export default {
	data() {
		return {
			// 订单编号
			creater_uid: "",
			// 客户名称
			name: "",
			// 跟进人
			department_id: "",
			staffFlag: false,

			start: "",
			count_mode: null,
			// 时间
			date: null,
			status: null,
			// orderStatus: ["关闭", "新建", "进行中", "已完成"],
			columns: [
				{
					title: '序号', dataIndex: 'num', width: 80, align: 'center',
					scopedSlots: { customRender: 'num' },
				},
				{ title: '员工姓名', dataIndex: 'name', width: 120 },
				{ title: '所属部门', dataIndex: 'cname', width: 120 },
				{ title: '时间', dataIndex: 'date_start', width: 120 },
				{ title: '订单数', dataIndex: 'order_num', width: 100 },
				{ title: '收款金额', dataIndex: 'amount', width: 120 },
				{ title: '计算方式', dataIndex: 'count_mode', width: 120 },
				{ title: '金额', dataIndex: 'money', width: 120 },
				{ title: '是否发放', dataIndex: 'status', width: 120,scopedSlots: { customRender: 'status' } },
				// {
				// 	title: '操作',
				// 	key: 'operation',
				// 	fixed: 'right',
				// 	width: 250,
				// 	scopedSlots: { customRender: 'Action' },
				// }
			],
			data: [],
			treeData: [],
			selectedRows: [],
			rowSelection: {
				onSelect: (record, selected, selectedRows) => {
					console.log(record, selected, selectedRows);
					this.selectedRows = selectedRows;
				},
				onSelectAll: (selected, selectedRows, changeRows) => {
					console.log(selected, selectedRows, changeRows);
					this.selectedRows = selectedRows;
				},
			},

			// 分页
			pagination: {
				current: 1,
				total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
				showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
				onChange: (current) => this.changePage(current), //点击当前页码
				onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
					console.log(pageSize);
					this.showSizeChange(current, pageSize)
				},
			},
			selectedRowKeys: []
		};
	},
	mounted: function () {
		this.getOrder();
		this.getField();

	},
	methods: {
		changeStatus(status,id){
			requestXml("/scrm/staff/setPayrollStatus", "POST", (res) => {
				if (res == "success") {
					this.getOrder();
				}
			}, { "id": id,'status':status?1:0 })
			console.log(status, "dasds")
		},
		// 分配完 取消表格选中 清空客户 重新加载列表
		updateStaffFlag(data) {
			if (data != 1) {
				this.staff_uid = data.userid;
				this.staff_uid2 = data.name;
			}
			this.staffFlag = false;
		},
		// 搜索
		onSearch() {
			if (this.date) {
				this.date._d.setHours(0);
				this.date._d.setMinutes(0);
				this.date._d.setSeconds(0);
				this.start = this.date._d.getTime();
			} else {
				this.start = null;
			}
			this.pagination.current = 1;
			this.getOrder();
		},
		reset() {
			this.name = "";
			this.department_id = "";
			this.date = null;
			this.start = null;
			this.count_mode = null;
			this.department_id = "";
			this.getOrder();
		},
		getField() {
			requestXml("/scrm/Index/getDepartment", "GET", (res) => {
				this.treeData = res;
			})
			// console.log(this.columns,"this.columns")
		},
		getOrder() {
			requestXml("/scrm/staff/getPayrollList", "POST", (res) => {
				for (let i = 0; i < res.list.length; i++) {
					res.list[i].created = getDateTime(res.list[i].created);
					// res.list[i].date_start = getDateTime(res.list[i].date_start);
					// res.list[i].date_end = getDateTime(res.list[i].date_end);
					// res.list[i].status = res.list[i].status == 1 ? '已发放' : '未发放'
					res.list[i].count_mode = res.list[i].count_mode == 1 ? '按单计算' : '按收款金额计算'
					if (getDateTime(res.list[i].date_start) == getDateTime(res.list[i].date_end)) {
						res.list[i].date_start = getDateTime(res.list[i].date_start);
					} else {
						res.list[i].date_start = getDateTime(res.list[i].date_start, 5)
					}
				}
				this.pagination.total = Number(res.page.total);
				this.pagination.pageSize = Number(res.page.pagesize);
				this.data = res.list;

			}, {
				"name": this.name,
				"department_id": this.department_id,
				"date": this.start,
				"count_mode": this.count_mode,
				"status": this.status,
				"page": { "curpage": this.pagination.current, "pagesize": this.pagination.pageSize }
			})
		},
		// 分页
		changePage(current) {
			//current参数表示是点击当前的页码，
			// console.log(current)
			this.pagination.current = current;
			this.getOrder(); //向后端发送请求
		},
		showSizeChange(current, pageSize) {
			// console.log(current, pageSize)
			this.pagination.current = current;
			this.pagination.pageSize = pageSize;
			this.getOrder(); //向后端发送请求
		},
		// 查看
		showDetail(id) {
			this.$router.push({
				path: "/scrm_pc/client/purchaseDetail",
				query: {
					id: id,
					// staff_uid: this.staff_uid
				}
			});
		},
		// 编辑
		editOrder(id) {
			this.$router.push({
				path: "/scrm_pc/client/addPurchase",
				query: {
					id: id,
				}
			});
		},
		// 删除
		onDelete(id) {
			requestXml("/scrm/corp/delpurchase", "POST", (res) => {
				if (res == "success") {
					this.getOrder();
				}
			}, { "id": id })
		},
		delAll() {
			if (this.selectedRows.length < 1) {
				this.$message.info('请先选择');
				return false;
			} else {
				let idArr = [];
				let sel = this.selectedRows;
				for (let i = 0; i < sel.length; i++) {
					idArr.push(sel[i].id);
				}
				this.onDelete(idArr);
			}
		},
		// 导出
		excelout() {
			console.log(ajaxUrl)
			window.open(ajaxUrl + 
				"/scrm/staff/excelPayroll?name=" + JSON.stringify(this.name)+
				'&department_id=' + JSON.stringify(this.department_id)+
				'&status=' + JSON.stringify(this.status)+
				'&count_mode=' + JSON.stringify(this.count_mode)+
				'&date=' + JSON.stringify(this.start));
		},
	},

};
</script>
<style scoped>
.order .search .ant-calendar-picker {
	width: 300px !important;
}

.action {
	display: inline-block;
	padding: 5px 10px;
	margin: 0 5px;
	background: #DCEEFF;
	border: 1px solid #36A3FF;
	color: #36A3FF;
	border-radius: 5px;
}

.del {
	background: #FFDCDC;
	border-color: #FF4C4C;
	color: #FF4C4C;
}
</style>
